import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { LoaderAndUtilsService } from '@core/services/loaderAndUitils.service';

import { combineLatest } from 'rxjs';
import { SubSink } from 'subsink';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'ic-loader',
  template: `
    @if (loading$) {
    <div class="fixed left-0 top-0 z-[9999] block h-full w-full bg-ica-bg-loading">
      <ng-lottie
        width="400px"
        height="500px"
        containerClass="moving-box"
        [styles]="styles"
        [options]="options"
        (animationCreated)="animationCreated($event)" />
    </div>
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LottieComponent],
})
export class LoaderComponent implements AfterViewInit, OnDestroy {
  private readonly subs = new SubSink();
  private readonly loaderService = inject(LoaderAndUtilsService);
  private readonly cd = inject(ChangeDetectorRef);

  loading$: boolean;

  options: AnimationOptions = {
    path: '/assets/Loader_Cars.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',
    margin: '0 auto',
  };

  ngAfterViewInit(): void {
    this.subs.add(
      combineLatest([this.loaderService.isLoading$, this.loaderService.notLoading$]).subscribe(
        ([loading, notLoading]) => {
          this.loading$ = loading && !notLoading;
          this.cd.detectChanges();
        }
      )
    );
  }

  animationCreated(animationItem: AnimationItem): void {
    //console.log(animationItem);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
